import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getMerchants(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecoupon/get_merchants', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getMerchant(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/ecoupon/get_merchant/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMerchant(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ecoupon/add_merchant', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editMerchant(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecoupon/edit_merchant/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMerchant(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecoupon/delete_merchant/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
