import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { t } from '@/@core/libs/i18n/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMerchantList() {
  // Use toast
  const toast = useToast()

  const refMerchantListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: t('Merchant Name'), sortable: true },
    { key: 'description', label: t('Description'), sortable: true },
    { key: 'status', label: t('Status'), sortable: true },
    { key: 'actions', label: t('Actions') },
  ]
  const perPage = ref(10)
  const totalMerchants = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refMerchantListTable.value ? refMerchantListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMerchants.value,
    }
  })

  const refetchData = () => {
    refMerchantListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const getMerchants = (ctx, callback) => {
    store
      .dispatch('merchant-store/getMerchants', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { merchants, total } = response.data

        callback(merchants)
        totalMerchants.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching merchants' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveMerchantStatusVariantAndIcon = status => {
    if (status === 'active') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'inactive') return { variant: 'danger', icon: 'ArrowDownCircleIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  return {
    getMerchants,
    tableColumns,
    perPage,
    currentPage,
    totalMerchants,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMerchantListTable,

    statusFilter,
    textFirstUpper,

    resolveMerchantStatusVariantAndIcon,

    refetchData,
  }
}
