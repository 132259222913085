<template>
  <b-modal
    id="form-merchant"
    :visible="shallShowMerchantFormModal"
    :title="`${$t('Form')} ${$t('Merchant')}`"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    hide-backdrop
    static
    @change="(val) => $emit('update:shall-show-merchant-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeMerchantForm)} ${$t('Merchant')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-merchant-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeMerchantForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ (typeMerchantForm === 'Add' || typeMerchantForm === 'Edit') ? $t('Save Data') : $t('Delete Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div class="mx-1">
            <b-row>

              <!-- Field: Name -->
              <b-col
                cols="12"
                md="4"
                class="my-1"
              >
                <b-form-group
                  v-if="(typeMerchantForm === 'Add' || typeMerchantForm === 'Edit')"
                  :label="$t('Merchant Name')"
                  label-for="merchant-name"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Merchant Name')"
                    rules="required"
                  >
                    <b-form-input
                      id="merchant-name"
                      v-model="merchantData.name"
                      :state="getValidationState(validationContext)"
                      :disabled="busy"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('Merchant Name')"
                  label-for="merchant-name"
                >
                  <b-form-input
                    id="merchant-name"
                    v-model="merchantData.name"
                    :readonly="true"
                  />
                </b-form-group>

              </b-col>

              <!-- Field: Description -->
              <b-col
                cols="12"
                md="4"
                class="my-1"
              >
                <b-form-group
                  v-if="(typeMerchantForm === 'Add' || typeMerchantForm === 'Edit')"
                  :label="$t('Description')"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="merchantData.description"
                    :disabled="busy"
                  />
                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('Description')"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="merchantData.description"
                    :readonly="true"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Status -->
              <b-col
                cols="12"
                md="6"
                lg="4"
                class="my-1"
              >
                <b-form-group
                  v-if="(typeMerchantForm === 'Add' || typeMerchantForm === 'Edit')"
                  :label="$t('Status')"
                  label-for="status"
                  label-class="mb-1"
                >
                  <b-form-radio-group
                    id="status"
                    v-model="merchantData.status"
                    :options="statusOptions"
                    value="active"
                  />
                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('Status')"
                  label-for="status"
                >
                  <b-badge
                    pill
                    :variant="`light-${resolveMerchantStatusVariantAndIcon(merchantData.status).variant}`"
                    class="p-1"
                  >
                    <feather-icon
                      :icon="resolveMerchantStatusVariantAndIcon(merchantData.status).icon"
                    />
                    {{ $t(textFirstUpper(merchantData.status)) }}
                  </b-badge>
                </b-form-group>
              </b-col>

            </b-row>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BBadge,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BOverlay,
  BProgress,
  BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  // ref,
  onUnmounted,
  // computed,
} from '@vue/composition-api'
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import { t } from '@/@core/libs/i18n/utils'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import merchantStoreModule from './merchantStoreModule'
import useMerchantList from './useMerchantList'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BBadge,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BProgress,
    BCardText,

    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'shallShowMerchantFormModal',
    event: 'update:shall-show-merchant-form-modal',
  },
  props: {
    shallShowMerchantFormModal: {
      type: Boolean,
      required: true,
    },
    typeMerchantForm: {
      type: String,
      default: 'Add',
    },
    merchantData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
    }
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeMerchantForm === 'Add') {
        store.dispatch('merchant-store/addMerchant', this.merchantData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('merchant-form-update')
            this.$emit('update:shall-show-merchant-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeMerchantForm === 'Edit') {
        store.dispatch('merchant-store/editMerchant', this.merchantData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('merchant-form-update')
            this.$emit('update:shall-show-merchant-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeMerchantForm === 'Delete') {
        store.dispatch('merchant-store/deleteMerchant', {
          id: this.merchantData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('merchant-form-update')
            this.$emit('update:shall-show-merchant-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-merchant-form')
      this.$emit('update:shall-show-merchant-form-modal', false)
    },
  },
  setup() {
    const BRAND_STORE_MODULE_NAME = 'merchant-store'

    // Register module
    if (!store.hasModule(BRAND_STORE_MODULE_NAME)) store.registerModule(BRAND_STORE_MODULE_NAME, merchantStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRAND_STORE_MODULE_NAME)) store.unregisterModule(BRAND_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { text: t('Active'), value: 'active' },
      { text: t('Inactive'), value: 'inactive' },
    ]

    // const blankMerchant = {
    //   name: '',
    //   description: '',
    //   status: 'active',
    // }
    // const merchantLocal = ref(JSON.parse(JSON.stringify(blankMerchant)))
    // const merchantLocal = computed(() => props.merchantData)
    // const resetMerchantLocal = () => {
    //   console.log(props.merchantData)
    //   merchantLocal.value = JSON.parse(JSON.stringify(blankMerchant))
    // }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    // const saveMerchant = () => {
    //   // resetMerchantLocal()
    //   emit('update:shall-show-merchant-form-modal', false)
    // }

    // const discardMerchant = () => {
    //   console.log('discard merchant')
    //   // resetMerchantLocal()
    //   emit('update:shall-show-merchant-form-modal', false)
    // }

    const {
      textFirstUpper,
      resolveMerchantStatusVariantAndIcon,
    } = useMerchantList()

    return {
      // merchantLocal,
      statusOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // Form actions
      // saveMerchant,
      // discardMerchant,

      textFirstUpper,
      resolveMerchantStatusVariantAndIcon,
    }
  },
}
</script>
