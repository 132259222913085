<template>
  <div style="height: inherit">

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-model="checkSelected"
              class="custom-control-primary"
              switch
              @change="changeSelect"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
            <label>{{ $t('Entries') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <!-- <b-button
              variant="success"
              :to="{ name: 'ecoupon-merchant-add'}"
            >
              {{ `${$t('Add')} ${$t('Merchant')}` }}
            </b-button> -->

            <b-button
              variant="primary"
              @click="addMerchantForm"
            >
              {{ `${$t('Add')} ${$t('Merchant')}` }}
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="`${$t('Search')}...`"
              />
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :clearable="true"
                class="merchant-filter-select"
                :placeholder="`${$t('Select')} ${$t('Status')}`"
                :reduce="val => val.value"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refMerchantListTable"
        :items="getMerchants"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        hover
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
      >

        <template #head(status)>
          <feather-icon
            icon="TrendingUpIcon"
            class="mx-auto"
          />
          {{ $t('Status') }}
        </template>

        <template #cell(name)="data">
          <b-link
            class="font-weight-bold"
            @click="viewMerchantForm(data)"
          >
            {{ data.value }}
          </b-link>
        </template>

        <template #cell(description)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveMerchantStatusVariantAndIcon(data.item.status).variant}`"
          >
            <feather-icon
              :icon="resolveMerchantStatusVariantAndIcon(data.item.status).icon"
            />
            {{ $t(textFirstUpper(data.value)) }}
          </b-badge>
        </template>

        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`merchant-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="editMerchantForm(data)"
            />
            <b-tooltip
              :title="`${$t('Edit')} ${$t('Merchant')}`"
              :target="`merchant-row-${data.item.id}-edit-icon`"
              :delay="{ show: 1000, hide: 50 }"
              placement="left"
            />

            <feather-icon
              :id="`merchant-row-${data.item.id}-view-icon`"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer mr-1"
              @click="viewMerchantForm(data)"
            />
            <b-tooltip
              :title="`${$t('View')} ${$t('Merchant')}`"
              :target="`merchant-row-${data.item.id}-view-icon`"
              :delay="{ show: 1000, hide: 50 }"
              placement="left"
            />

            <feather-icon
              :id="`merchant-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              variant="light-success"
              size="16"
              class="cursor-pointer mr-1"
              @click="deleteMerchantForm(data)"
            />
            <b-tooltip
              :title="`${$t('Delete')} ${$t('Merchant')}`"
              :target="`merchant-row-${data.item.id}-delete-icon`"
              :delay="{ show: 1000, hide: 50 }"
              placement="right"
            />

          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('Showing {from} to {to} of {of} entries',
                    {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}
            </span></b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMerchants"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <merchant-form
      v-model="shallShowMerchantFormModal"
      :merchant-data="merchantData"
      :type-merchant-form="typeMerchantForm"
      @merchant-form-update="updateMerchantForm"
      @discard-merchant-form="discardMerchantForm"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BPagination, BTooltip, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { t } from '@/@core/libs/i18n/utils'
import useMerchantList from './useMerchantList'

import merchantStoreModule from './merchantStoreModule'
import MerchantForm from './MerchantForm.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BTooltip,
    BFormCheckbox,

    vSelect,
    MerchantForm,
  },
  data() {
    return {
      // merchantData: {},
      selected: [],
      checkSelected: false,
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
      if (this.selected.length > 0) {
        this.checkSelected = true
      } else {
        this.checkSelected = false
      }
    },
    changeSelect() {
      if (this.checkSelected === true) {
        this.$refs.refMerchantListTable.selectAllRows()
      } else {
        this.$refs.refMerchantListTable.clearSelected()
      }
    },
    addMerchantForm() {
      this.typeMerchantForm = 'Add'
      this.shallShowMerchantFormModal = true
    },
    editMerchantForm(data) {
      this.merchantData = data.item
      this.typeMerchantForm = 'Edit'
      this.shallShowMerchantFormModal = true
    },
    viewMerchantForm(data) {
      this.merchantData = data.item
      this.typeMerchantForm = 'View'
      this.shallShowMerchantFormModal = true
    },
    deleteMerchantForm(data) {
      this.merchantData = data.item
      this.typeMerchantForm = 'Delete'
      this.shallShowMerchantFormModal = true
    },
    updateMerchantForm() {
      this.resetMerchantData()
      this.refetchData()
    },
    discardMerchantForm() {
      this.resetMerchantData()
      this.refetchData()
    },
  },
  setup() {
    const shallShowMerchantFormModal = ref(false)
    // const merchantData = ref({})
    const typeMerchantForm = ref('')

    const BRAND_STORE_MODULE_NAME = 'merchant-store'

    // Register module
    if (!store.hasModule(BRAND_STORE_MODULE_NAME)) store.registerModule(BRAND_STORE_MODULE_NAME, merchantStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRAND_STORE_MODULE_NAME)) store.unregisterModule(BRAND_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: t('Active'), value: 'active' },
      { label: t('Inactive'), value: 'inactive' },
    ]

    const blankMerchant = {
      name: '',
      description: '',
      status: 'active',
    }
    const merchantData = ref(JSON.parse(JSON.stringify(blankMerchant)))
    const resetMerchantData = () => {
      merchantData.value = JSON.parse(JSON.stringify(blankMerchant))
    }

    const {
      getMerchants,
      tableColumns,
      perPage,
      currentPage,
      totalMerchants,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMerchantListTable,

      statusFilter,
      textFirstUpper,

      refetchData,

      resolveMerchantStatusVariantAndIcon,
    } = useMerchantList()

    return {
      shallShowMerchantFormModal,
      merchantData,
      resetMerchantData,
      typeMerchantForm,
      getMerchants,
      tableColumns,
      perPage,
      currentPage,
      totalMerchants,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMerchantListTable,

      statusFilter,
      textFirstUpper,

      refetchData,

      statusOptions,

      avatarText,
      resolveMerchantStatusVariantAndIcon,
      blankMerchant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.merchant-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/e-coupon/merchant.scss";
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>
